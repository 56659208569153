import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt";

const API_URL = process.env.VUE_APP_BASE_API || "http://localhost:9800/v1";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },
  setHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = JwtService.getToken();
  },
  query(resource, params) {
    return Vue.axios.get(resource, params);
  },
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`);
  },
  post(resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config);
  },
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  delete(resource, params) {
    return Vue.axios.delete(resource, params);
  },
  download(resource, config) {
    return Vue.axios.get(resource, config);
  },
};
export default ApiService;

//日志服务
export const LogService = {
  get(resource, slug) {
    return ApiService.get("/logs" + resource, slug);
  },
  add(params) {
    return ApiService.post("/logs", params);
  },
};

//账号服务
export const AccountService = {
  get(resource, slug) {
    return ApiService.get("/account" + resource, slug);
  },
  create(resource, params) {
    return ApiService.post("/account" + resource, params);
  },
  update(slug, params) {
    return ApiService.update("/account", slug, params);
  },
  remove(resource) {
    return ApiService.delete("/account" + resource);
  },
};

//客户管理
export const CustomerService = {
  get(resource, slug) {
    return ApiService.get("/customer" + resource, slug);
  },
  update(resource, params) {
    return ApiService.put("/customer" + resource, params);
  },
  create(resource, params) {
    return ApiService.post("/" + resource, params);
  },
  delete(slug) {
    return ApiService.delete("/customer" + slug);
  },
};

//代理商管理
export const AgencyService = {
  get(resource, slug) {
    return ApiService.get("/agency" + resource, slug);
  },
  update(resource, params) {
    return ApiService.put("/agency" + resource, params);
  },
  create(resource, params) {
    return ApiService.post("/agency" + resource, params);
  },
  delete(slug) {
    return ApiService.delete("/agency" + slug);
  },
};

//合同管理
export const ContractService = {
  get(resource, slug) {
    return ApiService.get("/contract" + resource, slug);
  },
  update(resource, params) {
    return ApiService.put("/contract" + resource, params);
  },
  create(resource, params) {
    return ApiService.post("/contract" + resource, params);
  },
  delete(slug) {
    return ApiService.delete("/contract" + slug);
  },
};

//结算管理
export const SettleService = {
  get(resource, slug) {
    return ApiService.get("/settle" + resource, slug);
  },
  update(resource, params) {
    return ApiService.put("/settle" + resource, params);
  },
  create(resource, params) {
    return ApiService.post("/settle/import" + resource, params);
  },
  delete(slug) {
    return ApiService.delete("/settle" + slug);
  },
};

//工单管理
export const CaseService = {
  get(resource, slug) {
    return ApiService.get("/case" + resource, slug);
  },
  update(resource, params) {
    return ApiService.put("/case" + resource, params);
  },
  create(resource, params) {
    return ApiService.post("/case/settle" + resource, params);
  },
  submit(slug) {
    return ApiService.put("/case/settle/submit" + slug);
  },
  reject(slug) {
    return ApiService.put("/case/settle/reject" + slug);
  },
  delete(slug) {
    return ApiService.delete("/case" + slug);
  },
};
