//登录、注销、生成路由信息
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const PURGE_AUTH = "logOut";
export const SET_ERROR = "setError";
export const SET_ROUTERS = 'setRouters';
//账号相关
export const GET_ACCOUNTS = 'startAccountList';
export const SET_ACCOUNTS = 'setAccountList';
//日志
export const SET_LOGS = 'addLog';
export const LOAD_LOGS = 'startLogsList';
export const SET_LOGS_LIST = 'setLogsList';

//客户管理
export const LOAD_CUSTOMER_LIST = 'startCustomerList';
export const SET_CUSTOMER_LIST = 'setCustomerList';
export const SET_CUSTOMER = 'startCustomer';

//代理商管理
export const LOAD_AGENCY_LIST = 'startAgencyList';
export const SET_AGENCY_LIST = 'setAgencyList';
export const SET_AGENCY = 'startAgency';

//合同管理
export const LOAD_CONTRACT_LIST = 'startContractList';
export const SET_CONTRACT_LIST = 'setContractList';
export const SET_CONTRACT_CUSTOMER = 'setContractCustomerList';
export const SET_CONTRACT = 'startContract';
export const RELOAD_CONTRACT_LIST = 'reloadContractList';

//结算管理
export const LOAD_SETTLE_LIST = 'startSettleList';
export const SET_SETTLE_LIST = 'setSettleList';
export const SET_SETTLE = 'startSettle';
export const SET_SELECTED_SETTLE = 'setSelectedSettleList';

//工单管理
export const LOAD_CASE_LIST = 'startCaseList';
export const SET_CASE_LIST = 'setCaseList';
export const SET_CASE = 'startCase';
