import {SET_ERROR} from "./mutations.type";

const state = {
  msg: '',
  color: '',
  timeout: 6000,
};
const getters = {

};
const actions = {

};
const mutations = {
  [SET_ERROR](state, data) {
    state.msg = data.msg;
    data.color ? state.color = data.color : state.color = 'error';
    data.timeout ? state.timeout = data.timeout : state.timeout = 6000;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};


