//登录、注销、生成路由信息
export const CHECK_AUTH = 'checkAuth';
export const LOGIN_PASS = 'loginByPassword';
export const LOGIN_CODE = 'loginBySMSCode';
export const LOGOUT = 'logout';

//账号相关
export const CREATE_ACCOUNT = 'register';
export const FETCH_ACCOUNT_LIST = 'getAccountList';
export const CHECK_USERNAME = 'checkUserName';
export const CHECK_USERPHONE = 'checkUserPhone';
export const EDIT_ACCOUNT = 'updateMineAccount';

//代理商管理
export const CREATE_AGENCY = 'createAgency';
export const FETCH_AGENCY_LIST = 'getAgencyList';
export const FETCH_AGENCY = 'getAgency';
export const EDIT_AGENCY = 'updateAgency';
export const REMOVE_AGENCY = 'removeAgency';

//工单管理
export const CREATE_CASE = 'createCase';
export const FETCH_CASE_LIST = 'getCaseList';
export const FETCH_CASE = 'getCase';
export const EDIT_CASE = 'updateCase';
export const SUBMIT_CASE = 'submitCase';
export const REJECT_CASE = 'rejectCase';
export const REMOVE_CASE = 'removeCase';
