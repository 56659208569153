import {CaseService} from "@/common/http";

import {
    CREATE_CASE,
    FETCH_CASE_LIST,
    FETCH_CASE,
    EDIT_CASE,
    SUBMIT_CASE,
    REJECT_CASE,
    REMOVE_CASE,
} from "./actions.type";

import {
    SET_ERROR,
    LOAD_CASE_LIST,
    SET_CASE_LIST,
    SET_CASE,
} from "./mutations.type";

const state = {
    caseLoading: false,
    caseList: [],
    caseCount: 0,
    createCount: 0,
    remainCount: 0,
    caseInfo: {},
    caseSelected: {},
};

const getters = {
    caseLoading: state => state.caseLoading,
    caseList: state => state.caseList,
    caseCount: state => state.caseCount,
    createCount: state => state.createCount,
    remainCount: state => state.remainCount,
    caseInfo: state => state.caseInfo,
    caseSelected: state => state.caseSelected,
};

const actions = {
    [CREATE_CASE](context, params) {
        return new Promise((resolve, reject) => {
            CaseService.create('', params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                })
        });
    },
    [FETCH_CASE]({ commit }, params) {
        commit(LOAD_CASE_LIST);
        return new Promise((resolve, reject) => {
            CaseService.get('/settle', params.query)
                .then(({ data }) => {
                    commit(SET_CASE, data);
                    resolve(data);
                })
                .catch(error => {
                    commit(SET_ERROR, { msg: error });
                    reject(error);
                });
        });
    },
    [EDIT_CASE](context, params) {
        return CaseService.update('/' +  params.id, params.item);
    },
    [SUBMIT_CASE](context, data) {
        return CaseService.submit('/' + data);
    },
    [REJECT_CASE](context, data) {
        return CaseService.reject('/' + data);
    },
    [REMOVE_CASE](context, data) {
        return CaseService.delete('/' + data);
    },
    [FETCH_CASE_LIST]({ commit }, params) {
        commit(LOAD_CASE_LIST);
        return new Promise((resolve, reject) => {
            CaseService.get('/settle/list', '?'+params.query)
                .then(({ data }) => {
                    commit(SET_CASE_LIST, data);
                    resolve(data);
                })
                .catch((error) => {
                    commit(SET_ERROR, { msg: error });
                    reject(error);
                })
        });
    },
}

const mutations = {
    [LOAD_CASE_LIST] (state) {
        state.caseLoading = true;
    },
    [SET_CASE_LIST](state, list) {
        state.caseCount = list.query.count;
        state.createCount = list.query.createCount;
        state.remainCount = list.query.remainCount;
        state.caseList = list.docs.map(i => {
            i.settle_price_total = 0
            i.settleList.map(j => i.settle_price_total += Number(j.agency_fee.$numberDecimal))
            return i
        });
        state.caseLoading = false;
    },
    [SET_CASE](state, data) {
        state.caseInfo = data.caseInfo;
        state.caseLoading = false;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
