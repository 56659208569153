import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import account from "./account.module";
import agency from "./agency.module";
import cases from "./cases.module";
import error from "./error.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth, account, cases, agency, error
    }
});
