import ApiService from "@/common/http";
import JwtService from "@/common/jwt";
import Util from "@/common/util";
import { LOGIN_PASS, LOGIN_CODE, LOGOUT, CHECK_AUTH } from "./actions.type";
import {
  SET_AUTH,
  SET_USER,
  PURGE_AUTH,
  SET_ERROR,
  SET_LOGS,
} from "./mutations.type";

const state = {
  user: {},
  employer: {},
  isAuthenticated: !!JwtService.getToken(),
  routes: [],
  businessType: [],
};

const getters = {
  currentUser: (state) => state.user,
  currentEmployer: (state) => state.employer,
  isAuthenticated: (state) => state.isAuthenticated,
  userBusinessType: (state) => state.businessType,
};

const actions = {
  [LOGIN_PASS](context, credentials) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append("username", credentials.username);
      form.append("password", credentials.password);
      let param = new URLSearchParams(form);
      ApiService.post("/auth/password", param)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.token);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [LOGIN_CODE](context, credentials) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append("username", credentials.username);
      form.append("password", credentials.password);
      let param = new URLSearchParams(form);
      ApiService.post("/auth/smscode", param)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.token);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [CHECK_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/auth/mine")
          .then(({ data }) => {
            context.commit(SET_USER, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          });
      } else {
        context.commit(PURGE_AUTH);
        reject({ message: "token is null" });
      }
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve) => {
      context.commit(SET_LOGS, {
        operator: state.user._id,
        action: "Logout",
        objType: "Account",
        objId: [state.user._id],
      });
      context.commit(PURGE_AUTH);
      resolve();
    });
  },
};

const mutations = {
  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    JwtService.saveToken(token);
  },
  [SET_USER](state, data) {
    let arrPermission = [],
      arrService = [];
    data.employer.roles.forEach((item) => {
      arrPermission.push(item.permission);
      arrService.push(item.service);
    });
    state.user = Object.assign(data, {
      permission: [...new Set(arrPermission.flat())],
      service: [...new Set(arrService.flat())],
    });
    state.employer = data.employer;
    state.businessType = resetUserBusinessType(data);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = null;
    state.employer = null;
    JwtService.destroyToken();
  },
};

function resetUserBusinessType(user) {
  let type = Util.categories("businessType");
  if (user.hasAdmin.includes("distributor")) {
    return type;
  } else {
    let group = user.employer.group;
    let businessType = [];
    group.forEach((item) => {
      type.forEach((t, i) => {
        if (item === type[i].code) businessType.push(type[i]);
      });
    });
    return businessType;
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
};
