import { categories } from '@/common/categories';

const randomElement = (arr = []) => {
    return arr[Math.floor(Math.random() * arr.length)];
};

const kebab =  (str) => {
    return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

const toggleFullScreen = () => {
    let doc = window.document;
    let docEl = doc.documentElement;

    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl);
    }
    else {
        cancelFullScreen.call(doc);
    }
};

// ["2020/2/26", "2020/2/27", "2020/2/28", "2020/2/29", "2020/3/1", "2020/3/2", "2020/3/3"]
const getWeekTime = () => {
    return [...new Array(7)].map((j,i)=> new Date(Date.now()+i*8.64e7).toLocaleDateString())
};
//
// isType([], 'Array') // true
// isType(/\d/, 'RegExp') // true
// isType(new Date(), 'Date') // true
// isType(function(){}, 'Function') // true
// isType(Symbol(1), 'Symbol') // true
/**
 * @param {any} target
 * @param {string} type
 * @return {boolean}
 */
const isType = (target, type) => {
    let targetType = Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
    return targetType === type.toLowerCase()
};

// let data = {
//   id: 1,
//   title: 'xxx',
//   comment: []}omit(data, ['id']) // {title: 'xxx', comment: []}
/**
 * @param {object} object
 * @param {string[]} props
 * @return {object}
 */
const omit = (object, props=[]) => {
    let res = {}
    Object.keys(object).forEach(key=>{
        if(props.includes(key) === false){
            res[key] = typeof object[key] === 'object' && object[key] !== null ?
                JSON.parse(JSON.stringify(object[key])):
                object[key]
        }})
    return res
};

const wait = timeout => {
    return new Promise(resolve => setTimeout(resolve, timeout))
};

export default {
    randomElement,
    toggleFullScreen,
    categories,
    kebab,
    getWeekTime,
    isType,
    omit,
    wait
};
