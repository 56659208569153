<template>
  <v-app>
      <v-main>
          <v-fade-transition mode="out-in">
              <router-view />
          </v-fade-transition>
      </v-main>
      <core-error />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {

  },

  data: () => ({
    //
  }),
};
</script>
