import Vue from 'vue'
import App from './App.vue'

import router from '@/router';
import store from '@/store';

import ApiService from '@/common/http';

import vuetify from './common/vuetify'

import './components';
import '@/common/filters';
import './router/auth';

import VueMask from 'vue-the-mask'
Vue.use(VueMask)

Vue.config.productionTip = false
Vue.prototype.ossURL = process.env.VUE_APP_OSS_URL || 'localhost:9800';

ApiService.init();

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
