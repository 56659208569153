import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export const H5Router = [
    {
        path: '/',
        hidden: false,
        name: 'home',
        component: () => import('@/views/index'),
        meta: {
            login_required: true
        }
    },
    {
        path: '/login',
        hidden: false,
        name: 'login',
        component: () => import('@/views/login'),
        meta: {
            login_required: false
        }
    },
]

export default new Router({
    base: "/",
    mode: "hash",
    scrollBehavior: () => ({ y: 0 }),
    routes: H5Router
})