export const categories = (target) => {
    let objTarget = {}
    switch (target) {
        case 'permission':
            objTarget = [
                { title: '客户管理', name: 'Customer_Edit', icon: 'assignment' },
                { title: '代理商管理', name: 'Agent_Edit', icon: 'assignment' },
                { title: '合同管理', name: 'Contract_Edit', icon: 'assignment' },
                { title: '结算管理-能源', name: 'Settle_Energy', icon: 'assignment' },
                { title: '结算管理-售电', name: 'Settle_Sale', icon: 'assignment' },
                { title: '结算管理-调峰', name: 'Settle_Peak', icon: 'assignment' },
                { title: '结算管理-智科', name: 'Settle_ZhiKe', icon: 'assignment' },
                { title: '数据分析', name: 'Report_Data', icon: 'assignment' },
                { title: '储备客户', name: 'Filing_Edit', icon: 'assignment' },
                { title: '系统设置 > 组织权限', name: 'System_Organize', icon: 'settings' },
                { title: '系统设置 > 区域设置', name: 'System_Area', icon: 'settings' },
                { title: '系统设置 > 流程设置', name: 'System_Task', icon: 'settings' }
            ]
            break
        case 'electricType':
            objTarget = [
                { name: '工业', code: 1 },
                { name: '商业', code: 2 }
            ]
            break
        case 'businessType':
            objTarget = [
                { name: '能源', code: 1 },
                { name: '调峰', code: 2 },
                { name: '售电', code: 3 },
                { name: '智科', code: 4 }
            ]
            break
        case 'otherBusinessType':
            objTarget = [
                { name: '采购', code: 5 },
                { name: '行政', code: 6 }
            ]
            break
        case 'signType':
            objTarget = [
                { name: '比例分成', code: 1 },
                { name: '固定服务费', code: 2 },
                { name: '固定单价', code: 3 }
            ]
            break
        case 'businessGroup':
            objTarget = [
                { name: '能源', code: 1, icon: 'sunny_snowing' },
                { name: '调峰', code: 2, icon: 'electric_meter' },
                { name: '售电', code: 3, icon: 'offline_bolt' },
                { name: '智科', code: 4, icon: 'broadcast_on_personal' },
            ]
            break
        case 'dictEnable':
            objTarget = [
                { name: '全部', code: null},
                { name: '是', code: true},
                { name: '否', code: false},
            ]
            break
        case 'contractStatus':
            objTarget = [
                { name: '履约中', code: 1 },
                { name: '已终止', code: 2 },
            ]
            break
        case 'invoiceType':
            objTarget = [
                { name: '增值税普通发票', code: '0' },
                { name: '增值税专用发票', code: '1' },
            ]
            break
        case 'corpType':
            objTarget = [
                { name: "民营", code: 1 },
                { name: "外资", code: 2 },
                { name: "合资", code: 3 },
                { name: "事业单位", code: 4 },
            ]
            break
        case 'shortcutsLink':
            objTarget = [
                { name: "新增客户", code: 10, link: "/distributor/customer/edit", icon: "add_home", color: "light-green", roles: "Customer_Edit" },
                { name: "新增代理商", code: 20, link: "/distributor/agency/edit", icon: "add_home_work", color: "light-green ", roles: "Agent_Edit" },
                { name: "创建其他合同", code: 30, link: "", icon: "note_add", color: "light-green ", roles: "Contract_Edit" },
                { name: "上传能源结算", code: 41, link: "/distributor/settle/list", icon: "upload_file", color: "primary", roles: "Settle_Energy" },
                { name: "上传售电结算", code: 42, link: "/distributor/settle/list", icon: "upload_file", color: "primary", roles: "Settle_Sale" },
                { name: "上传调峰结算", code: 43, link: "/distributor/settle/list", icon: "upload_file", color: "primary", roles: "Settle_Peak" },
                { name: "创建结算工单", code: 50, link: "/distributor/settle/list", icon: "task", color: "light-green ", roles: "Customer_Edit" },
                { name: "新增储备客户", code: 60, link: "/distributor/filing/edit/0", icon: "bookmark_added", color: "light-green ", roles: "Customer_Edit" },
                { name: "设置部门", code: 91, link: "/distributor/system/organize/department", icon: "schema", color: "amber", roles: "System_Organize" },
                { name: "配置权限", code: 92, link: "/distributor/system/organize/roles", icon: "admin_panel_settings", color: "amber", roles: "System_Organize" },
                { name: "维护区域", code: 93, link: "/distributor/system/area", icon: "hdr_strong", color: "amber", roles: "System_Area" },
            ]
            break
        case 'caseStatus':
            objTarget = [
                { name: "进行中", code: 1 },
                { name: "已取消", code: 2 },
                { name: "已完成", code: 4 },
            ]
            break
    }
    return objTarget
};

export default {
    categories,
};
