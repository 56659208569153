import Vue from "vue";
import m from 'moment'
m.locale('zh-cn'); // 使用中文
// Filter - FormatTime
// DateTime > '2019/1/31 00:00:00'
Vue.filter('formatTime', function(val) {
    let time = val;
    if (isNaN(Date.parse(val))) {
        if (val) time = val.replace(/-/g, '/').replace(/T/g, ' ');
    }

    let date = new Date(time);
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    const t1 = [year, month, day].map(formatNumber).join('/')
    const t2 = [hour, minute, second].map(formatNumber).join(':')

    return `${t1} ${t2}`
});
// Filter - fromDate
// String > 4 年前
Vue.filter('fromDate', function (val) {
    let date = new Date(val);
    return m(date).fromNow();
});

// Filter - FormatDate
// Date > 'YYYY-MM-DD'
Vue.filter('formatDate', function (val) {
    if (val) {
        let value = new Date(val);
        let year = value.getFullYear();
        let month = padDate(value.getMonth() + 1);
        let day = padDate(value.getDate());
        return year +'-'+ month +'-'+ day;
    }
});

// Filter - FormatMonth
// Date > 'YYYY-MM-'
Vue.filter('formatMonth', function (val) {
    if (val) {
        let value = new Date(val);
        let year = value.getFullYear();
        let month = padDate(value.getMonth() + 1);
        return year +'-'+ month;
    }
});



/**
 * @description 格式化金额
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
Vue.filter('formatMoney', function (value, str) {
    if (!str) str = '';
    // str 规定货币类型
    if (value == "0") return '0.00';
    if(value == ".") return '';
    if (!value) return '';
    let val = Number(value).toFixed(2) // 提前保留两位小数
    let intPart = parseInt(val) // 获取整数部分
    let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断 ？？？
    let floatPart = '.00' // 预定义小数部分
    val = val.toString() // 将number类型转为字符串类型，方便操作
    let value2Array = val.split('.')
    if (value2Array.length === 2) { // =2表示数据有小数位
        floatPart = value2Array[1].toString() // 拿到小数部分
        if (floatPart.length === 1) { // 补0,实际上用不着
            return str + intPartFormat + '.' + floatPart + '0'
        } else {
            return str + intPartFormat + '.' + floatPart
        }
    } else {
        return str + intPartFormat + floatPart
    }
})
// 100.1234
Vue.filter('formatMoneyDecimal', function (value, str) {
    if (!str) str = '';
    // str 规定货币类型
    if (value == "0") return '0.0000';
    if (value == ".") return '';
    if (!value) return '';
    let val = Number(value).toFixed(4) // 提前保留两位小数
    let intPart = parseInt(val) // 获取整数部分
    let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断 ？？？
    let floatPart = '.0000' // 预定义小数部分
    val = val.toString() // 将number类型转为字符串类型，方便操作
    let value2Array = val.split('.')
    if (value2Array.length === 2) { // =2表示数据有小数位
        floatPart = value2Array[1].toString() // 拿到小数部分
        if (floatPart.length === 1) { // 补0,实际上用不着
            return str + intPartFormat + '.' + floatPart + '0'
        } else {
            return str + intPartFormat + '.' + floatPart
        }
    } else {
        return str + intPartFormat + floatPart
    }
})

//15亿6204万5698.88
Vue.filter('formatMoneyUnitFloat', function (val) {
    // 防止空字符返回为0
    if (val === '' || !val) return val
    let toNumberVal = Number(val)
    if (isNaN(toNumberVal)) {
        return val
    }
    let flag = ''
    if (toNumberVal < 0) {
        flag = '-'
        toNumberVal = Math.abs(toNumberVal)
    }
    if (toNumberVal >= 10000) {
        // 大于等于10000的数据要去掉小数位
        const numStrArr = String(toNumberVal).split('.')
        const numStr = numStrArr[0]
        const len = numStr.length
        const numArr = []
        // 每4位截取一次，截取小于10000部分的数据
        let itemLast = Number(numStr.substring(len - 4, len))
        // 只有个位数前面要加0 等于0则去掉，不加入数组拼接
        if (itemLast < 10 && itemLast > 0) {
            numArr.unshift('0' + itemLast)
        } else if (itemLast >= 10) {
            numArr.unshift(itemLast)
        }
        let itemCenter = 0
        let itemFirst = 0
        if (len <= 8) {
            // 长度小于等于8， 没有亿位，这个时候万位可以从index = 0 开始截取
            itemCenter = Number(numStr.substring(0, len - 4))
        } else {
            // 长度大于8才有亿， 万位不能再从index = 0开始截取
            itemCenter = Number(numStr.substring(len - 8, len - 4))
            itemFirst = Number(numStr.substring(0, len - 8))
        }
        // 万位和亿位如果大于0才加入数组拼接
        if (itemCenter > 0) numArr.unshift(itemCenter + '万')
        if (itemFirst > 0) numArr.unshift(itemFirst + '亿')
        return flag + numArr.join('') + (numStrArr[1] ? `.${numStrArr[1]}` : '')
    }
    return flag + toNumberVal
})

//15亿6204万5698
Vue.filter('formatMoneyUnitInt', function (val) {
    // 防止空字符返回为0
    if (val === '') return val
    let toNumberVal = Number(val)
    if (isNaN(toNumberVal)) {
        return val
    }
    let flag = ''
    if (toNumberVal < 0) {
        flag = '-'
        toNumberVal = Math.abs(toNumberVal)
    }
    if (toNumberVal >= 10000) {
        // 大于等于10000的数据要去掉小数位
        const numStr = Math.floor(toNumberVal) + ''
        const len = numStr.length
        const numArr = []
        // 每4位截取一次，截取小于10000部分的数据
        let itemLast = Number(numStr.substring(len - 4, len))
        // 只有个位数前面要加0 等于0则去掉，不加入数组拼接
        if (itemLast < 10 && itemLast > 0) {
            numArr.unshift('0' + itemLast)
        } else if (itemLast >= 10) {
            numArr.unshift(itemLast)
        }
        let itemCenter = 0
        let itemFirst = 0
        if (len <= 8) {
            // 长度小于等于8， 没有亿位，这个时候万位可以从index = 0 开始截取
            itemCenter = Number(numStr.substring(0, len - 4))
        } else {
            // 长度大于8才有亿， 万位不能再从index = 0开始截取
            itemCenter = Number(numStr.substring(len - 8, len - 4))
            itemFirst = Number(numStr.substring(0, len - 8))
        }
        // 万位和亿位如果大于0才加入数组拼接
        if (itemCenter > 0) numArr.unshift(itemCenter + '万')
        if (itemFirst > 0) numArr.unshift(itemFirst + '亿')
        return flag + numArr.join('')
    }
    return flag + toNumberVal
})

//15亿6204万5698
Vue.filter('formatMoneyUnit', function (val) {
    // 防止空字符返回为0
    if (val === '') return val
    let toNumberVal = Number(val)
    if (isNaN(toNumberVal)) {
        return val
    }
    let flag = ''
    if (toNumberVal < 0) {
        flag = '-'
        toNumberVal = Math.abs(toNumberVal)
    }
    if (toNumberVal >= 10000) {
        // 大于等于10000的数据要去掉小数位
        const numStr = Math.floor(toNumberVal) + ''
        const len = numStr.length
        const numArr = []
        // 每4位截取一次，截取小于10000部分的数据
        let itemLast = Number(numStr.substring(len - 4, len))
        // 只有个位数前面要加0 等于0则去掉，不加入数组拼接
        if (itemLast < 10 && itemLast > 0) {
            numArr.unshift('0' + itemLast)
        } else if (itemLast >= 10) {
            numArr.unshift(itemLast)
        }
        let itemCenter = 0
        let itemFirst = 0
        if (len <= 8) {
            // 长度小于等于8， 没有亿位，这个时候万位可以从index = 0 开始截取
            itemCenter = Number(numStr.substring(0, len - 4))
        } else {
            // 长度大于8才有亿， 万位不能再从index = 0开始截取
            itemCenter = Number(numStr.substring(len - 8, len - 4))
            itemFirst = Number(numStr.substring(0, len - 8))
        }
        // 万位和亿位如果大于0才加入数组拼接
        if (itemCenter > 0) numArr.unshift(itemCenter + '万')
        if (itemFirst > 0) numArr.unshift(itemFirst + '亿')
        return flag + numArr.join('')
    }
    return flag + toNumberVal
})

// Filter - toCNText
// String > 账户
Vue.filter('stringModule', function (val) {
    if (val === 'Account') return '人员';
    if (val === 'Asset') return '资产';
    if (val === 'Produce') return '产品';
    if (val === 'Workflow') return '流程';
    if (val === 'white') return '纯白色';
    if (val === 'primary') return '倍升灰';
    if (val === 'transparent') return '透明色';
    if (val === 'display-4') return '4号标题';
    if (val === 'display-3') return '3号标题';
    if (val === 'display-2') return '2号标题';
    if (val === 'display-1') return '1号标题';
    if (val === 'headline') return '大标题';
    if (val === 'title') return '标题';
    if (val === 'subheading') return '子标题';
    if (val === 'red') return '标准红';
    if (val === 'info') return '标准黑';
    if (val === 'secondary') return '鑫泰灰';
    if (val === 'purple') return '标准紫';
    if (val === 'deep-purple') return '深紫色';
    if (val === 'indigo') return '靛蓝色';
    if (val === 'blue') return '标准蓝';
    if (val === 'light-blue') return '浅蓝色';
    if (val === 'cyan') return '青蓝色';
    if (val === 'teal') return '水绿色';
    if (val === 'green') return '标准绿';
    if (val === 'light-green') return '浅绿色';
    if (val === 'lime') return '柠檬色';
    if (val === 'yellow') return '标准黄';
    if (val === 'amber') return '琥珀色';
    if (val === 'orange') return '橘橙色';
    if (val === 'deep-orange') return '深橙色';
    if (val === 'brown') return '棕褐色';
    if (val === 'blue-grey') return '青灰色';
    if (val === 'grey') return '标准灰';
    if (val === 'primary') return '倍升灰';
    if (val === 'white') return '纯白色';
    if (val === '#f5f5f5') return '浅灰色';
    if (val === 'start') return '开始位置';
    if (val === 'center') return '中间位置';
    if (val === 'end') return '结尾位置';
    if (val === 'baseline') return '起点位置';
    if (val === 'stretch') return '拉伸';
    if (val === 'space-around') return '居中分散';
    if (val === 'space-between') return '两端分散';
    if (val === 'Create') return '创建';
    if (val === 'Update') return '修改';
    if (val === 'Delete') return '删除';
    if (val === 'Sequence') return '串行';
    if (val === 'Synchronization') return '并行';
    if (val === 'Choice') return '执行其一';
    if (val === 'Account') return '人员';
    if (val === 'Asset') return '资产';
    if (val === 'Product') return '产品';
    if (val === 'Task') return '任务';
})

Vue.filter('NumToChs', function (amount) {
    const cnNums = [
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖",
    ];
    // 基本单位
    const cnIntRadice = ["", "拾", "佰", "仟"];
    // 对应整数部分扩展单位
    const cnIntUnits = ["", "万", "亿", "兆"];
    // 对应小数部分单位
    const cnDecUnits = ["角", "分"];
    // 整数金额时后面跟的字符
    const cnInteger = "整";
    // 整型完以后的单位
    const cnIntLast = "元";
    // 最大处理的数字
    const maxNum = 9999999999999999.99;
    // 金额整数部分
    let integerNum;
    // 金额小数部分
    let decimalNum;
    // 输出的中文金额字符串
    let chineseStr = "";
    // 分离金额后用的数组，预定义
    let parts;
    if (amount === "") {
        return "";
    }
    amount = parseFloat(amount);
    if (amount >= maxNum) {
        // 超出最大处理数字
        return "";
    }
    if (amount === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    // 转换为字符串
    amount = amount.toString();
    if (amount.indexOf(".") === -1) {
        integerNum = amount;

        decimalNum = "";
    } else {
        parts = amount.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    // 获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        const IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
            const n = integerNum.substr(i, 1);
            const p = IntLen - i - 1;
            const q = p / 4;
            const m = p % 4;
            if (n === "0") {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                // 归零
                zeroCount = 0;
                //alert(cnNums[parseInt(n)])
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m === 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    // 小数部分
    if (decimalNum !== "") {
        const decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            const n = decimalNum.substr(i, 1);
            if (n !== "0") {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr === "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum === "") {
        chineseStr += cnInteger;
    }
    return chineseStr;//转好的大写文字
})

function padDate (va) {
    va = va < 10 ? '0'+va : va;
    return va;
}
function formatNumber(n) {
    const str = n.toString()
    return str[1] ? str : `0${str}`
}
